<template>
  <full-view :title="getTitle">
    <template v-slot:toolbar>
      <v-btn :disabled="!valid" large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto pa-0" :style="getViewHeightStyle(56)">
        <v-form ref="form" v-model="valid" class="mb-5">
          <v-row no-gutters>
            <v-col cols="12" md="7">
              <div class="pa-5">
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="item.name"
                      :counter="64"
                      :rules="rules.name"
                      :label="$t('general.name')"
                      required
                      dense
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.nameAr"
                      :counter="64"
                      :rules="rules.name"
                      :label="$t('general.nameAr')"
                      required
                      dense
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-textarea
                      v-model="item.description"
                      :counter="255"
                      :rules="rules.limited255"
                      :label="$t('general.description')"
                      dense
                      rows="3"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="item.active"
                      :label="$t('general.active')"
                      dense
                    />
                  </v-col>
                </v-row>
              </div>

            </v-col>


            <v-col cols="12" md="5" class="left-shadow">
              <div class="pa-5">
                <image-uploader
                  :title="$t('general.icon')"
                  v-model="item.image"
                  :store="getStore"
                />
              </div>
            </v-col>
          </v-row>

          <form-bar
            :valid="valid"
            @save="save"
            @reset="reset"
            @validate="$refs.form.validate()"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import ImageUploader from "@/common/components/ImageUploader";
import FormBar from "@/common/components/FormBar";

export default {
  name: "SectionForm",
  components: { FullView, ImageUploader, FormBar },
  data() {
    return {
      item: {},
      valid: false,
      originalState: { id: 0, storeId: this.getStoreIdParam },
      rules: {
        name: [
          (v) => !!v || "First name is required",
          (v) => (!!v && v.length >= 3) || "Name must be at least 5 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        limited255: [
          (v) =>
            !v || v.length <= 255 || "This field is limited to 255 characters",
        ],
      },
    };
  },
  mounted() {
    if (this.editMode) {
      this.loadSectionByUuid(this.uuidParam).then((item) => {
        this.item = item;
        Object.assign(this.originalState, this.item);
      });
      this.loadStore(this.getStoreIdParam);
    } else {
      this.item = {
        storeId: this.getStoreIdParam
      };
    }
  },
  computed: {
    ...mapGetters("stores", ["getStoreById"]),

    getTitle() {
      if (this.editMode) {
        return this.$t("store.section.edit");
      } else {
        return this.$t("store.section.new");
      }
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getStore() {
      return this.getStoreById(this.getStoreIdParam);
    },
    uuidParam() {
      return this.$route.params.sectionUuid;
    },
    editMode() {
      return !!this.uuidParam && this.uuidParam !== "new";
    },
    isAdmin() {
      return this.$hasRole("SUPER-ADMIN") || this.$hasRole("ADMIN");
    },
  },
  methods: {
    ...mapActions("stores", ["loadStore"]),
    ...mapActions("sections", ["loadSectionByUuid", "saveSection"]),

    save() {
      this.saveSection({
        item: this.item,
        editMode: this.editMode,
      }).then(() => {
        this.$$router.back();
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
